import SwipeListener from 'swipe-listener'

export default class GalleryCTA {
  constructor() {
    this.galleries = Array.from(document.querySelectorAll('.gallery-cta'))

    this.galleries.forEach((gallery) => {
      const stageLeft = gallery.querySelector('.gallery-cta__left')
      const stageRight = gallery.querySelector('.gallery-cta__right')

      if (window.innerWidth > 960) {
        gallery.addEventListener('mousemove', (event) => {
          const windowWidth = window.innerWidth

          const x = event.clientX
          const y = event.clientY

          if (gallery.classList.contains('right')) {
            stageRight.classList.remove('show')

            if (x < windowWidth / 2) {
              stageLeft.classList.add('show')
              stageLeft.style.top = `${y - 63}px`
              stageLeft.style.left = `${x - 63}px`
            } else {
              stageLeft.classList.remove('show')
            }
          } else {
            stageLeft.classList.remove('show')

            if (x > windowWidth / 2) {
              stageRight.classList.add('show')
              stageRight.style.top = `${y - 63}px`
              stageRight.style.left = `${x - 63}px`
            } else {
              stageRight.classList.remove('show')
            }
          }
        })
      }

      gallery.addEventListener('mouseleave', () => {
        stageLeft.classList.remove('show')
        stageRight.classList.remove('show')
      })

      stageRight.addEventListener('click', () => {
        gallery.classList.add('right')
        stageRight.classList.remove('show')
      })

      stageLeft.addEventListener('click', () => {
        gallery.classList.remove('right')
        stageLeft.classList.remove('show')
      })

      const listener = SwipeListener(gallery)

      gallery.addEventListener('swipe', (e) => {
        var directions = e.detail.directions
        var x = e.detail.x
        var y = e.detail.y

        if (directions.left) {
          this.setPageClass(gallery)
        }

        if (directions.right) {
          this.setPageClass(gallery, true)
        }
      })
    })
  }

  setPageClass(gallery, subtract) {
    const classes = gallery.classList

    if (subtract) {
      if (classes.contains('page-4')) {
        classes.remove('page-4')
        classes.add('page-3')
      } else if (classes.contains('page-3')) {
        classes.remove('page-3')
        classes.add('page-2')
      } else if (classes.contains('page-2')) {
        classes.remove('page-2')
      }
    } else {
      if (classes.contains('page-3')) {
        classes.remove('page-3')
        classes.add('page-4')
      } else if (classes.contains('page-2')) {
        classes.remove('page-2')
        classes.add('page-3')
      } else if (
        !classes.contains('page-2') &&
        !classes.contains('page-3') &&
        !classes.contains('page-4')
      ) {
        classes.add('page-2')
      }
    }
  }
}
