import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import objectFitVideos from 'object-fit-videos'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import GalleryCTA from './gallery-cta'
import Menu from './menu'
import Posts from './posts'
import Sliders from './sliders'
import Formbuilder from './formbuilder'
import Video from './video'
import WorkBlock from './work-block'
import quizIntro from './quiz-intro'
import comparisonChart from './comparison-chart'
import Alert from './alert'
import { accordion } from './accordion'
import { forms } from './forms'

objectFitImages()
objectFitVideos()

new GalleryCTA()
new Menu()
new Alert()
new Posts()
new Sliders()
new Formbuilder()
new Video()
new WorkBlock()
new quizIntro()
new comparisonChart()
forms()
accordion()
