export default class quizIntro {
  constructor() {
    this.quizIntro = Array.from(document.querySelectorAll('.quiz-intro'))
    const mainQuiz = document.getElementById('quiz')

    this.quizIntro.forEach((item) => {
      const goCircle = item.querySelector('.lets-go')

      item.addEventListener('mousemove', (event) => {
        const windowWidth = window.innerWidth

        const x = event.clientX
        const y = event.clientY

        if (windowWidth > 768 && item.classList.contains('open')) {
          goCircle.style.top = `${y - 63}px`
          goCircle.style.left = `${x - 63}px`
        }
      })

      item.addEventListener('click', () => {
        item.classList.remove('open')
        mainQuiz.classList.add('open')
      })
    })
  }
}
