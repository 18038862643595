export default class WorkBlock {
  constructor() {
    window.scrollTo({ top: 0, behavior: 'instant' })
    const windowWidth = window.innerWidth

    this.workBlocks = Array.from(
      document.querySelectorAll('.work-block__section'),
    )
    this.workSections = Array.from(document.querySelectorAll('.work-block'))

    this.workBlocks.forEach((workBlock) => {
      const viewCircle = workBlock.querySelector('.work-block-view-arrow')
      const close = workBlock.querySelector('.close')

      workBlock.addEventListener('mousemove', (event) => {
        const x = event.clientX
        const y = event.clientY

        if (windowWidth > 960 && !workBlock.classList.contains('open')) {
          viewCircle.style.top = `${y - 63}px`
          viewCircle.style.left = `${x - 63}px`
        }
      })

      close.addEventListener('click', () => {
        setTimeout(() => {
          workBlock.classList.remove('open')
          document.body.classList.remove('no-scroll')
          close.setAttribute('aria-hidden', 'true')
        }, 100)
      })

      workBlock.addEventListener('click', () => {
        if (
          windowWidth > 960 &&
          !workBlock.classList.contains('open') &&
          workBlock.classList.contains('work-block__section--active')
        ) {
          workBlock.classList.add('open')
          document.body.classList.add('no-scroll')
          workBlock.querySelector('.close').setAttribute('aria-hidden', 'false')
        }
      })

      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          workBlock.classList.remove('open')
          document.body.classList.remove('no-scroll')
          workBlock.querySelector('.close').setAttribute('aria-hidden', 'true')
        }
      })
    })

    this.workSections.forEach((workSection) => {
      const headline = workSection.querySelector('.work-block-headline')
      const sectionH = workSection.offsetHeight
      const sectionPos = workSection.getBoundingClientRect()

      if (window.innerWidth > 960) {
        window.addEventListener('scroll', function () {
          const sectionOffset = sectionPos.top - sectionH / 2

          if (
            !headline.classList.contains('show') &&
            window.pageYOffset >= sectionOffset &&
            window.pageYOffset > 450
          ) {
            headline.classList.add('show')
          } else if (
            window.pageYOffset <= sectionOffset ||
            window.pageYOffset <= 450
          ) {
            headline.classList.remove('show')
          }
        })
      }
    })
  }
}
