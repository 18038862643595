export const accordion = () => {
  const accordions = document.querySelectorAll('.accordion__item')

  accordions.forEach((item) => {
    let button = item.querySelector('.accordion__item__header')

    item.addEventListener('click', () => {
      if (item.classList.contains('show')) {
        item.classList.remove('show')
        button.setAttribute('aria-expanded', 'false')
      } else {
        item.classList.add('show')
        button.setAttribute('aria-expanded', 'true')
      }
    })
  })
}
