export default class Menu {
  constructor() {
    this.body = $('body')
    this.header = $('.header')
    this.hamburger = $('.hamburger__wrapper')
    this.nav = $('.header__nav')
    this.close = document.querySelector('.close-menu')
    this.searchBox = document.querySelector('.search-box')

    if (this.header.length) {
      this.initMenu()
    }
  }

  initMenu() {
    this.hamburger.on('click', () => {
      this.body.toggleClass('no-scroll')
      this.header.toggleClass('open')
      this.hamburger.children().toggleClass('active')
      this.nav.stop().slideToggle(300)
    })

    const childrenToggles = document.querySelectorAll('.children-toggle')

    Array.from(childrenToggles).forEach((toggle) => {
      toggle.addEventListener('click', () => {
        toggle.classList.toggle('open')
        $(toggle).next().slideToggle()
      })
    })

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        if (
          this.header[0].classList.contains('open') &&
          !document.querySelector('.nav.active')
        ) {
          this.header[0].classList.remove('open')
          $('.hamburger').removeClass('active')
        }
      }

      if (!this.header[0].classList.contains('open')) {
        document.querySelector('.header__nav').style.display = 'none'
      } else {
        document.querySelector('.header__nav').style.display = 'block'
      }
    })

    const topButtons = Array.from(
      document.querySelectorAll('.header button.main-menu__item'),
    )

    this.close.addEventListener('click', () => {
      topButtons.forEach((b) => b.classList.remove('active'))
      this.header[0].classList.remove('open')
      this.close.classList.remove('show')
      this.searchBox.classList.remove('active')

      topButtons.forEach((b) => {
        const childNav = b.parentNode.querySelector('.nav')
        if (childNav) {
          childNav.classList.remove('active')
        }
      })
    })

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        const windowWidth = window.innerWidth

        if (windowWidth >= 1024) {
          topButtons.forEach((b) => b.classList.remove('active'))
          this.header[0].classList.remove('open')
          this.close.classList.remove('show')
          this.searchBox.classList.remove('active')

          topButtons.forEach((b) => {
            const childNav = b.parentNode.querySelector('.nav')
            if (childNav) {
              childNav.classList.remove('active')
            }
          })
        }
      }
    })

    const lastIndex = topButtons.length - 1

    topButtons.forEach((button, index) => {
      button.addEventListener('click', () => {
        if (index === lastIndex) {
          if (button.classList.contains('active')) {
            this.searchBox.classList.remove('active')
          } else {
            this.searchBox.classList.add('active')
          }
        } else {
          const childNav = button.parentNode.querySelector('.nav')
          this.searchBox.classList.remove('active')

          if (button.classList.contains('active')) {
            if (childNav) {
              childNav.classList.remove('active')
            }
          } else {
            topButtons.forEach((b) => {
              const childNav = b.parentNode.querySelector('.nav')
              if (childNav) {
                childNav.classList.remove('active')
              }
            })

            if (childNav) {
              childNav.classList.add('active')
            }
          }
        }

        if (button.classList.contains('active')) {
          button.classList.remove('active')
          this.header[0].classList.remove('open')
          this.close.classList.remove('show')
        } else {
          topButtons.forEach((b) => b.classList.remove('active'))
          button.classList.add('active')
          this.header[0].classList.add('open')
          this.close.classList.add('show')
        }
      })
    })
  }
}
