export default class Posts {
  constructor() {
    $(document).on('click', $('.posts__paginate a'), (event) => {
      const target = event.target

      if (target.parentNode.classList.contains('page')) {
        event.preventDefault()

        const link = event.target
        let url = link.href

        if (url) {
          if (!url.match('.js')) {
            url = url.replace('?', '.js?')
          }

          $.ajax({
            url,
            method: 'GET',
          })
        }
      }
    })
  }
}
