export const forms = () => {
    // This does not allow a form to be submitted until a response from the recaptcha
    const recaptcha = document.getElementById('form_2_validation')
    const recaptchaError = document.getElementById('recaptcha-error')
    const button = document.querySelector('.new_form_response .submit-button button')

    if (recaptcha){
        button.addEventListener("click",function(evt){
            var response = grecaptcha.getResponse();
            if(response.length == 0) { 
                //reCaptcha not verified
                recaptchaError.classList.add('active')
                evt.preventDefault()
                return false
            }
            
        });
        
    }
}
